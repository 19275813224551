// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import 'ngx-bar-rating/themes/br-default-theme';

:root {
  --ion-color-primary: #1eb9d5;
  --ion-color-primary-rgb: 30,185,213;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #1aa3bb;
  --ion-color-primary-tint: #35c0d9;

  --ion-color-secondary: #bad51e;
  --ion-color-secondary-rgb: 186,213,30;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #a4bb1a;
  --ion-color-secondary-tint: #c1d935;

  --ion-color-tertiary: #d51eba;
  --ion-color-tertiary-rgb: 213,30,186;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #bb1aa4;
  --ion-color-tertiary-tint: #d935c1;
  
    --ion-color-success: #34C034;
  --ion-color-success-rgb: 52,192,52;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #2ea92e;
  --ion-color-success-tint: #48c648;
  
    --ion-color-warning: #F0A541;
  --ion-color-warning-rgb: 240,165,65;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d39139;
  --ion-color-warning-tint: #f2ae54;
  
    --ion-color-danger: #F04141;
    --ion-color-danger-rgb: 240,65,65;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,34,34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
  
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,244,244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

  }

/*
* Custom Color
* --------------------------------------------
*/
.ion-color-alert {
  --ion-color-base: #ffff00;
  --ion-color-base-rgb: 255,255,0;
  --ion-color-contrast: #777777;
  --ion-color-contrast-rgb: 127,127,127;
  --ion-color-shade: #e0e000;
  --ion-color-tint: #ffff1a;
}

  /*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: #1eb9d5;
  --ion-color-primary-rgb: 30,185,213;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #1aa3bb;
  --ion-color-primary-tint: #35c0d9;

  --ion-color-secondary: #bad51e;
  --ion-color-secondary-rgb: 186,213,30;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #a4bb1a;
  --ion-color-secondary-tint: #c1d935;

  --ion-color-tertiary: #d51eba;
  --ion-color-tertiary-rgb: 213,30,186;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #bb1aa4;
  --ion-color-tertiary-tint: #d935c1;

  --ion-color-success: #34C034;
  --ion-color-success-rgb: 52,192,52;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #2ea92e;
  --ion-color-success-tint: #48c648;

  --ion-color-warning: #F0A541;
  --ion-color-warning-rgb: 240,165,65;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d39139;
  --ion-color-warning-tint: #f2ae54;

  --ion-color-danger: #F04141;
  --ion-color-danger-rgb: 240,65,65;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1A1B1E;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
 60% { transform: rotate(5deg); }
 65% { transform: rotate(0deg); }
 70% { transform: rotate(-5deg); }
 75% { transform: rotate(0deg); }
 80% { transform: rotate(5deg); }
 85% { transform: rotate(0deg); }
 90% { transform: rotate(-5deg); }
100% { transform: rotate(0deg); }
}

.wiggle {
animation: wiggle 1s infinite;
}

.wiggle:hover {
animation: none;
}