// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Lato-Regular.ttf');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/Lato-Bold.ttf');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: normal;
    src: url('../assets/fonts/Lato-Italic.ttf');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: bold;
    src: url('../assets/fonts/Lato-BoldItalic.ttf');
  }
  
  :root {
  --ion-font-family: 'Lato';
  }